<template>
  <ModernModalLayout persistent title="Редактирование изображения">
    <Spinner v-if="loading" />
    <Cropper
      ref="cropper"
      style="overflow: hidden; max-height: 65vh; min-height: 300px"
      :default-size="defaultSize"
      :src="fileAsUrl"
      @error="errorHandler"
    />
    <canvas ref="editor" style="display: none" />
    <v-col class="d-flex justify-center">
      <div class="d-flex mr-3">
        <v-btn outlined text @click="() => setBrightness(10)">
          <v-icon>mdi-weather-sunset-up</v-icon>
        </v-btn>
        <v-btn outlined text @click="() => setBrightness(-10)">
          <v-icon>mdi-weather-sunset-down</v-icon>
        </v-btn>
      </div>
    </v-col>

    <template #actions:append>
      <v-btn :disabled="disabled" depressed color="primary" @click="cropImage">
        Подтвердить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import Spinner from '@/components/Spinner.vue';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';

export default {
  components: { Cropper, Spinner, ModernModalLayout },

  props: {
    value: { type: [File, String], default: null },
    loading: Boolean,
    disabled: Boolean,
    change: {
      type: Function,
      default: null,
    },
    width: {
      type: String,
      default: '450px',
    },
  },

  data: () => ({
    fileAsUrl: null,
    isCropProcess: false,
    canvasParams: {},
  }),

  computed: {
    format() {
      if (typeof this.value === 'string') {
        return this.value.split('?')[0].split('.').pop() || null;
      }
      return this.value.name.split('.').pop() || null;
    },

    mimeType() {
      if (this.value?.type) return this.value.type;

      switch ((this.format || '').toLowerCase()) {
        case 'jpg':
        case 'jpeg':
          return `image/jpeg`;
        case 'bmp':
          return `image/bmp`;
        default:
          return `image/png`;
      }
    },
  },

  async created() {
    if (this.value instanceof File) {
      this.fileAsUrl = URL.createObjectURL(this.value);
    } else if (typeof this.value === 'string') {
      this.fileAsUrl = this.value;
    } else throw Error(`Unsupported file type ${this.value}`);
  },

  methods: {
    canvasEditor(cb) {
      const image = new Image();
      image.src = this.fileAsUrl;
      image.crossOrigin = 'anonymous';
      image.onload = () => {
        const canvas = this.$refs.editor;
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        const context = canvas.getContext('2d');
        context.drawImage(image, 0, 0);

        cb(context, image, canvas);

        context.fillRect(0, 0, image.naturalWidth, image.naturalHeight);
        this.$refs.cropper.imageAttributes.src = canvas.toDataURL(
          this.mimeType,
        );
      };
    },

    async setBrightness(value) {
      const newBrightness = (this.canvasParams.brightness || 0) + value;

      const operation = newBrightness >= 0 ? 'lighter' : 'darken';
      const color = newBrightness > 0 ? 255 : 0;
      const opacity = Math.abs(newBrightness) / 100;

      this.canvasParams.brightness = newBrightness;

      this.$nextTick(() =>
        this.canvasEditor(ctx => {
          ctx.globalCompositeOperation = operation;
          ctx.fillStyle = `rgba(${color},${color},${color},${opacity})`;
        }),
      );
    },

    closeModal() {
      this.$emit('close');
    },

    cancel() {
      this.$emit('cancel');
      this.closeModal();
    },

    errorHandler(err) {
      this.$emit('error', err);
      this.closeModal();
    },

    async cropImage() {
      this.isCropProcess = true;
      const { canvas } = this.$refs.cropper.getResult();

      await canvas.toBlob(blob => {
        const name =
          this.value?.name || `image-${new Date().getTime()}${this.format}`;
        this.change(new File([blob], name, { type: this.mimeType }));
      }, this.mimeType);

      this.isCropProcess = false;
      this.closeModal();
    },

    defaultSize({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      };
    },
  },
};
</script>
